
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/*App { padding: 0 !important; }*/

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


body {
    background: white;
}

.title {
    font-size: 18px;
    margin: 0;
}

/*ul {*/
/*    font-size: 14px;*/
/*    list-style-type: none;*/
/*    padding: 0;*/
/*    margin: 0;*/
/*    display: flex;*/
/*}*/

li {
    /*margin: 0 10px;*/
}

li:hover {
    cursor: pointer;
    color: #84cef1;
}

.k-button {
    padding: 0;
    background-color: #DCE1EE;
    margin: 0 5px;
}

.k-badge-container {
    margin-right: 8px;
}

.k-event {
    min-width: 96px !important;
}

.main-screen {
    height: calc(100vh - 50px);

    padding: 20px;
}

.box {
    /*height: calc(100vh - 50px);*/
    overflow-y: scroll;
    padding: 20px;
}

/*.box:hover {*/
/*    -webkit-transform: scale(1.2);*/
/*    -moz-transform: scale(1.2);*/
/*    -o-transform: scale(1.2);*/
/*    -ms-transform: scale(1.2);*/
/*    transform: scale(1.2);*/
/*    box-shadow: 20px 20px 2px rgba(0, 0, 0, 0.5);*/
/*    position: relative;*/
/*    top: -.4em;*/
/*    background: #f2f2f2;*/
/*}*/

.box1 {
    display: inline-block;
    width: 30%;
    border: 1px solid #000;
    vertical-align: top;
}

.box h2, .box p {
    padding: 0 10px;
    margin: 0 0 1em
}

.k-drawer-container {
    position: fixed;


    /*!*overflow-x: scroll;*!*/
    width: 100%;
    height: 100%;
}

.k-drawer-item {
    user-select: none;
}

.my-custom-icon-class:before {
    content: "e810"; /* Adds a glyph using the Unicode character number */
}

.boxHeader .k-button.k-button-icon:hover,
.boxHeader .k-button.k-button-icon:focus,
.boxHeader .k-button.k-button-icon {
    background: none;
    color: #FFF;
    outline: none;
    border: none;
}

.msgText p {
    margin: 0px;
    font-size: 1em;
    font-weight: 400;
    color: rgb(108, 108, 118);
}

.k-tabstrip-items .k-link {
    padding: 0px !important;
}

.k-tabstrip-items-wrapper {
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
}

/*.k-tabstrip-items*/
/*{*/
/*    width: 100%;*/
/*    overflow-x: auto;*/
/*    overflow-y: hidden;*/
/*    white-space: nowrap;*/
/*}*/
.k-item.k-state-active .tabtitlewithIcon {
    background: #7585c2;
}


.tabtitlewithIcon {
    width: 100%;
    background: #BAC1E0;
    padding: 5px;
    text-align: center;
}

.tabtitlewithIcon .fa {
    width: 100%;
    padding: 5px 0;
    font-size: 1.5rem;
}

/*.k-tabstrip-items {*/
/*    padding: 0px;*/
/*    margin: 0px;*/
/*    overflow-x: scroll;*/
/*    overflow-y: hidden;*/
/*    height: 100px;*/
/*    width: 100%;*/
/*    display: flex;*/
/*    flex-flow: row;*/
/*    !*flex: 0 0 auto;*!*/
/*}*/
.tabtitlewithIcon .tabTitle,
.tabtitlewithIcon .fa {
    color: #FFF
}

.activityStreamPop .k-window-content {
    padding: 0px;
}

.activityStreamPop .k-window-content > div {
    padding-top: 0px !important;
}

.activityStreamPop .k-window-content .boxHeader {
    display: none;
}

.grid-patient-custom-12 {
    float: left;
    max-width: 950px;
    margin: 0 0 0 0;
    width: 100%;
    display: block;
}

.k-tilelayout.k-grid-flow-col.main-screen.user-header-component {
    width: calc(100% - 240px);
}

.add-user-button {
    border-radius: 5px;
}

.user-list-search {
    max-width: 950px;
    width: 100%;
    display: block;
    margin: 0;
}

.dictionary-list-search {
    max-width: 950px;
    width: 100%;
    display: block;
    margin: 0;
}

/*.add-dictionary-button, .rounded-button {*/
/*    border-color: transparent;*/
/*    border-radius: 5px;*/
/*    box-shadow: 0 0 0 2px*/
/*}*/

/*.add-patient-button {*/
/*    border: 1px solid #7585c2;*/
/*    color: #ffffff;*/
/*    background-color: #7585c2;*/
/*    box-shadow: 0 0 0 2px */
/*}*/

.add-patient-button .k-i-add::before {
    content: "\e11e";
    color: #fff;
}

.add-patient-button .k-i-add {
    margin-top: -4px;
    margin-right: 2px;
}

.k-content {
    height: 100%;
}

/*.k-tabstrip {*/
/*    height: 100%;*/
/*    width: 100%;*/
/*}*/

/*div.k-content.k-state-active.ah-tab > div {*/
/*    height: 100%;*/
/*    padding: 0;*/
/*}*/

/*.k-tabstrip-content, .k-tabstrip > .k-content {*/
/*    padding: 0;*/
/*}*/
/*.p-tabview .p-tabview-panels {*/
/*    padding: 0;*/
/*}*/

/*.patient-list-header .k-tilelayout-item-header.k-card-header {*/
/*    background: rgb(116, 132, 194);*/
/*}*/

/*.patient-list-header .k-tilelayout-item-header.k-card-header h5 {*/
/*    color: #fff*/
/*}*/

/*.user-list-header .k-tilelayout-item-header.k-card-header {*/
/*    background: rgb(116, 132, 194);*/
/*}*/

/*.user-list-header .k-tilelayout-item-header.k-card-header h5 {*/
/*    color: #fff*/
/*}*/

/*.k-button {*/
/*    border-radius: 5px !important;*/
/*}*/

/*.modal-common label.k-label {*/
/*    margin-right: 10px;*/
/*    padding-top: 5px;*/
/*    width: 43%;*/
/*    -ms-flex-direction: column;*/
/*    flex-direction: column;*/
/*    -ms-flex-align: end;*/
/*    align-items: flex-end;*/
/*    -ms-flex-pack: start;*/
/*    justify-content: flex-start;*/
/*}*/

/*.modal-common .k-dialog-titlebar {*/
/*    color: #ffffff;*/
/*    padding: 0;*/
/*    margin: 0;*/
/*    background-color: #ffff;*/
/*}*/

/*.k-dialog-titlebar {*/
/*    color: #ffffff;*/
/*    padding: 0;*/
/*    margin: 0;*/
/*    background-color: #ffff;*/
/*}*/

/*.modal-common .k-window-content, .modal-common .k-prompt-container {*/
/*    padding: 0*/
/*}*/

/*.k-window-content .sub-title {*/

/*    display: block;*/
/*    clear: both;*/
/*    padding: 9px 15px 9px;*/
/*    background: #0000ff1a;*/
/*    margin: 11px 0 18px;*/
/*}*/


/*.k-window-content .k-form-field .one-row {*/
/*    display: flex;*/

/*    margin: 0 10px 20px;*/
/*}*/

.block-sec {
    /* display: flex; */
    /* margin: 0 6px;
       max-width: 216px; */

}

label.k-label {
    margin-right: 7px;
    padding-top: 5px;
    /* width: 78px; */
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    font-size: 13px;
}

/* input.k-textbox {
    padding: 4px 8px;
    width: 97%;
} */

.heading-custom {
    margin: 0;
    padding: 1px 15px 23px;
}

.one-row-new {
    float: left;
    width: 97%;
    clear: both;
    border-top: 2px solid #9999;
    padding: 20px 0px;
    margin: 0 17px;
}

.no-border {
    border-top: 0;
}

.one-row-new .block-sec {
    float: left;

    display: block;
    width: 30%;
    max-width: 100%;
}

.one-row-new:after {
    content: "";
    clear: both;
    width: 100%;
    display: block;
}

.w-100 {
    max-width: 100%;
    width: 100%;
}

.disp-inline-block {
    display: inline-block;
}

.block-sec.w-50 {
    max-width: 50%;
    width: 100%;
}

.w-25 {
    max-width: 25%;
    width: 25%;
}

.mr-10 {
    margin-right: 10px
}

.mr-7 {
    margin-right: 7px
}

.mr-60 {
    margin-right: 60px;
}

.k-overlay {
    width: 100%;
    height: 100%;
    opacity: 0.32;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10001;
}

.radio-button-custom {
    padding-top: 24px;
    padding-left: 15px;
}

.radio-button-custom span {
    padding-left: 20px;
}

.custom-icon {
    padding-top: 27px;
    padding-left: 7px;
}

/*button.k-button-icon.k-button.k-flat:first-of-type {*/
/*    pointer-events: none;*/
/*    visibility: hidden;*/
/*}*/

/*button.k-button-icon.k-button.k-flat:nth-of-type(2) {*/
/*    pointer-events: none;*/
/*    visibility: hidden;*/
/*}*/

.k-widget.k-drawer.k-drawer-start {
    width: 240px
}

/* .k-drawer-content{
    width:calc(100% - 240px);
} */
.right-side {
    margin-top: 24px;
}

.d-flex {
    display: flex;
}

.d-flex .k-form-field-wrap {
    width: 240px;
}

.k-dropdown.select-custom, .k-dropdowntree.select-custom {
    max-width: 310px !important;
    width: 100% !important;
}

.k-window .k-window-title {
    color: #ffffff !important;
    text-transform: uppercase;
    font-weight: 700;
}



/*.css-10nakn3-MuiModal-root-MuiPopover-root-MuiMenu-root {*/
/*    z-index: 10150 !important;*/
/*}*/
/*.k-animation-container-relative {*/
/*    height: 100%;*/
/*}*/

/*.k-child-animation-container {*/
/*    height: 100%;*/
/*}*/

/*.ah-tab > div:first-child {*/
/*    height: calc(100% - 35px);*/
/*}*/
/*.ah-tab > div:first-child .ah-tab > div:first-child {*/
/*    height: calc(100% - 35px);*/
/*}*/




@media screen {
    .noPrint {
    }

    .noScreen {
        display: none;
    }
}

@media print {
    .noPrint {
        display: none;
    }

    .noScreen {
    }
}

.k-list-container {
    z-index: 99999 !important;
}

.custom-user-form label.k-label {
    width: 16% !important;
}

.k-form-horizontal
.custom-user-form .k-form-field-wrap {
    max-width: calc((100% - 16%) - 10px);

}

.user-right-form {
    border-left: 0.1px solid;
    width: 28% !important;
    border-bottom-left-radius: 6px;
    border-top-left-radius: 4px;
    position: relative;
    top: 20px;
}

.k-form-field {
    margin-top: 1em;
}

/*This messes up the filtering for the grid views.*/
/*.k-combobox, .k-dropdown, .k-dropdowntree {*/
/*    display: flex;*/
/*    width: 100% !important;*/
/*}*/
.upload-icon {
    color: rgb(87, 104, 255);
}

.baserequest .boxHeader .k-checkbox {
    width: 12px;
    height: 12px;
}

.baserequest
label.k-checkbox-label {
    color: #fff;
    text-transform: capitalize;
    font-size: 13px;
}

.baserequest thead {
    display: none;
}

.baserequest span.k-icon.k-i-bar-stacked100:before {
    content: "\ea07";
}

/* .role-base-access
.k-tilelayout.k-grid-flow-col.main-screen.user-header-component {
    width: calc(100% - 46px);
} */


.font-size-50 {
    font-size: 50px !important;
}

.pdfTable {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
}

.pdfTable tr {
    padding: 5px;
}

.pdfTable tr:nth-child(even) {
    background: #f2f2f2
}

.pdfTable tr th {
    text-align: left;
    border: 1px solid #ddd;
    padding: 8px;
    background: #eee
}

.pdfTable tr td {
    border: 1px solid #ddd;
    padding: 8px;
}

.hidden-down-request * {
    display: none !important;
}

.m-0 {
    margin: 0 !important;
}

.ml-0 {
    margin-left: 0 !important;
}

.appt {
    margin-top: 20px;
}

.mfa-method {
    margin-left: 10px;
    border: 2px solid;
    padding: 2px 5px;
}

.mfa-label, label.mfa-label {
    font-size: 19px;
    align-items: flex-start !important;
    padding: 0 !important;

}

.reset-mfa {
    border-color: #9e9fa3 !important;
    border-radius: 0 !important;
    background-color: #9e9fa3 !important;
}

.k-state-disabled.reset-mfa {
    opacity: 0.2;
}

.providerbase .k-label-empty {
    display: none !important;
}

.k-icon input.k-textbox {
    font-family: Lato, Helvetica, sans-serif;
}

button.k-button-custom {
    border-radius: 0 !important;
}

.k-avatar-solid.k-avatar-primary {
    margin-top: 2px !important;
}

.k-window-actions {
    color: #fff !important;
}

.w-100 {
    width: 100%;
    float: left;
}

.w-50 {
    width: 50%;
    float: left;
}

.w-49 {
    width: 49%;
    float: left;
}

.mr-2 {
    margin-left: 2%;
}

.k-hbox {
    padding-top: 5px !important;
}

.bold {
    font-weight: 900;
}

.company-name {
    font-size: 2em;
    text-align: center;
}

.base-request-form h5, .base-request-form h6 {
    margin: 0;
}

.base-request-form
label.k-label {
    padding-top: 0px;
}

.k-header {
    font-weight: bold !important;
}

.bloodPresRow td {
    color: #FFF;
    border-bottom: solid 2px #FFF !important;
    border-right: none !important;
    border-left: none !important;
}

.bloodPresHeader tr {
    background-color: #cccccc;
    height: 10px;
}

.grid thead {
    background-color: #cccccc;
}

.gridLayout th, .gridLayout td {
    padding: 5px 8px !important;
    border-style: solid;
    border-color: inherit;
    outline: 0;
    font-weight: inherit;
    text-align: inherit;
    overflow: hidden;
    text-overflow: ellipsis;
}

#k-icon-rows{
    color: white;
}

.ah-white-button > button {
    color: white;
    border: black;
}

div.sign-encounter-window div.k-window-title {
    text-transform: capitalize !important;
}

.k-tooltip-content{
    color: #fff !important;
}

.boxHeader {
    height: auto !important;
}

.boxHeader > div {
    height: auto !important;
}

.boxHeader > div > div{
    height: auto !important;
}

.custom-tooltip {
}

/* Tooltip text */
.custom-tooltip .tooltiptext {
    visibility: hidden;
    background-color: white;
    color: #fff;
    text-align: left;
    border-radius: 6px;

    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 2;
}

/* Show the tooltip text when you mouse over the tooltip container */
.custom-tooltip:hover .tooltiptext {
    visibility: visible;
}

.content-wrapper,
.content-wrapper img {
    width: 750px;
    height: 250px;
}

.signature-wrapper .k-signature {
    width: 100%;
    height: 100%;
}

.signature-wrapper img {
    user-select: none
}