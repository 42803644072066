$border-radius: 2px;
$primary: #7585c2;
$secondary: #9fa7c6;
$info: #3e80ed;
$success: #5ec232;
$warning: #fdce3e;
$error: #d51923;
$body-text: #424242;
$body-bg: #ffffff;
$headings-text: #272727;
$subtle-text: #666666;
$disabled-text: #8f8f8f;
$component-text: #424242;
$component-bg: #ffffff;
$base-text: #424242;
$base-bg: #fafafa;
$hovered-text: #424242;
$hovered-bg: #ececec;
$selected-text: #ffffff;
$selected-bg: #9fa7c6;
$button-text: #424242;
$button-bg: #f5f5f5;
$link-text: #ff6358;
$link-hover-text: #d6534a;
$series-a: #ff6358;
$series-b: #ffd246;
$series-c: #78d237;
$series-d: #28b4c8;
$series-e: #2d73f5;
$series-f: #aa46be;

//@import "~@progress/kendo-theme-bootstrap/dist/all.scss";