// Import configuration from the theme builder

.p-dialog-header-icon {
  color: #FFFFFF;
}


@import "index.css";
@import 'variables';
@import "~@progress/kendo-theme-default/dist/all.scss";
@import "assets/themes/mytheme/theme";
@import "primereact/resources/primereact.min.css";
@import "primeflex/primeflex.css";
@import 'primeicons/primeicons.css';
//@import 'simple-grid';


body {
  margin: 0;
}

#tabstrip-parent, #tabstrip {
  height: 100%;
  margin: 0;
  padding: 0;
  border-width: 0;
}

.k-tabstrip {
  height: 100%;
  border-width: 0;
}

.ah-tab > div {
  height: 100%;
  border-width: 0;
}


*:focus {
  box-shadow: none !important;
}

.p-listbox-list > li:nth-child(even) {
  background-color: rgb(220, 224, 238);
}

.sub-title {
  background-color: #e0e2eb;
  padding: 10px 0px 10px 10px;
  border-radius: 5px;
  margin: 10px 0px 10px 0px;
  width: 100%;
  //width: calc(100% - 15px);
  text-transform: uppercase;
  font-weight: bold;
  font-family: Roboto, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif;
}


.react-lock-screen__ui {
  z-index: 999999;
  width: 300px;
  position: fixed;
  border: 4px solid #333;
  background: #fff;
  text-align: center;
  padding: 16px;
  border-radius: 4px;
  top: calc(30% - 152px / 2);
  left: calc(49% - 300px / 2);
}

.k-animation-container {
  z-index: 100000 !important;
}

.MuiPopover-root {
  z-index: 100000 !important;
}

.fa-pulse {
  display: inline-block;
  -moz-animation: pulse 2s infinite linear;
  -o-animation: pulse 2s infinite linear;
  -webkit-animation: pulse 2s infinite linear;
  animation: pulse 2s infinite linear;
}

@-webkit-keyframes pulse {
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}
@-moz-keyframes pulse {
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}
@-o-keyframes pulse {
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}
@-ms-keyframes pulse {
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}
@keyframes pulse {
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}