.role-base-access .k-column-title {
    min-width: 0;
    text-overflow: initial;
    white-space: pre-wrap;
    flex-shrink: 1;
    overflow: hidden;
    font-size: 10px;
    text-align: center;
}
.role-base-access .k-grid-header table,th {
    border: none !important;
}


.role-base-access .k-widget.k-grid {
    border: none !important;
}

.role-base-access th.k-header:type-of(3) {
    border:1px solidl
}

.role-base-access .header-design{
    background: #7684c2;
    text-align: center;
    padding: 12px;
    color: #ffff;
    font-size: 12px;
    vertical-align: middle;
}
.role-base-access .k-grid th {
    padding: 8px 3px;
}

.role-base-access .k-grid th,.role-base-access .k-grid td {
    text-align: center !important;
    padding: 7px 4px;
}
.role-base-access .ScheduleCell {
    width: 40px;
    padding-left: 2px !important;
}


.role-base-access .header-design .close_btn {
    background: #7784c2;
    border: 1px solid #fff;
    border-radius: 3px;
    font-size: 12px;
    float: right;
}
.role-base-access .header-design 
.k-icon{
    color: #fff;
}