/*.k-panelbar > .k-item > .k-link, .k-panelbar > .k-panelbar-header > .k-link,*/
/*.k-panelbar > .k-item > .k-link, .k-panelbar > .k-panelbar-header > .k-link:hover {*/
/*    background-color: #D4D9E8 !important;*/
/*    border-color: #D4D9E8 !important;*/
/*}*/

.notification-panel-header {
    padding: 4px !important;
    background-color: #D4D9E8 !important;
    border-color: #D4D9E8 !important;
}

.notification-panel-header > .k-panelbar-item-text {
    width: 100% !important;
    text-align: center !important;
    color: black;
}

.notification-panel-header-urgent > .k-panelbar-item-text  {
    color: red
}