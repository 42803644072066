.ah_social_history .k-panelbar-item-text:before {
    content: "\25B8";
    color: #000;
    display: inline-block;
    font-size: 22px;
    margin-right: 10px;
}
.k-panelbar-item-text {
    font-weight: 700;
}

.ah_social_history .k-state-expanded .k-panelbar-item-text:before {
    content: "\25BE";
    color: #000;
    display: inline-block;
    font-size: 22px;
    margin-right: 10px;
}
.ah_social_history .k-panelbar-toggle {
    display:none;
}
.ah_social_history .k-panelbar > .k-item > .k-link.k-state-selected,
.ah_social_history .k-panelbar > .k-panelbar-header > .k-link.k-state-selected
.ah_social_history 
.k-panelbar > .k-item > .k-link.k-state-selected, 
.ah_social_history  .k-panelbar > .k-panelbar-header > .k-link.k-state-selected {
    color: #ff6358;
    background-color: #ffffff;
}
.ah_social_history 
.k-panelbar > .k-item + .k-item,
.ah_social_history  .k-panelbar > .k-panelbar-header + .k-panelbar-header {
    border-top-width: 0px;
    border-bottom-width: 0px;
}
